:root {
    --ghost-accent-color: #ff6717;
    --c-theme: var(--ghost-accent-color);
    --logo-header-height: 40px;
    --logo-footer-height: 40px;
    --c-white: #ffffff;
    --c-black: #000000;
    --c-success: #96EA8C;
    --c-error: #FF9B84;
    --c-button-text: var(--c-white);
    --size-1: 4px;
    --size-2: 8px;
    --size-3: 16px;
    --size-4: 24px;
    --c-body-bg: #ffffff;
    --c-text-main: #161F38;
    --c-text-light: #575861;
    --c-gray-light: #EFEFEF;
    --c-gray-lighter: #f9f9f9;
    --c-border: #DADCE5;
    --c-border-light: var(--c-gray-light);
    --c-shadow: 0 15px 40px 5px rgba(132, 132, 133, 0.15);
}
.blog-details-preview{
    

    p{
        font-size: 15px;
        margin: 20px 0;
    }
    .kg-bookmark-container {
        display: flex;
        flex-wrap: wrap;
        color: var(--c-text-main);
        text-decoration: none;
        flex-direction: column-reverse;
        border-radius: var(--size-3);
        border: 1px solid var(--c-gray-light);
        background: var(--c-gray-lighter);
        overflow: hidden;
    }
    .kg-bookmark-content {
        flex-basis: 0;
        flex-grow: 999;
        min-width: 50%;
        padding: 1.5rem;
    }
    .kg-bookmark-thumbnail {
        flex-basis: 216px;
        flex-grow: 1;
        position: relative;
        border-radius: 0 var(--border-radius-large) var(--border-radius-large) 0;
        overflow: hidden;
    }
    .post-content .kg-bookmark-thumbnail {
        flex-basis: 32%;
        min-height: 144px;
    }
    .h3, h3 {
        font-size: 1.5rem;
        margin-bottom: 16px;
    }
    .post-content figure {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    .kg-callout-card {
        display: flex;
        padding: 1.2em 1.6em;
        border-radius: 3px;
    }
    .kg-callout-card-purple {
        background: rgba(135, 85, 236, .12);
    }
    .post-content .kg-callout-card {
        border-radius: 16px;
    }
    .kg-button-card {
        display: inline-flex;
        position: static;
        align-items: center;
        width: 100%;
        justify-content: center;
    }
    .kg-button-card a.kg-btn-accent {
        background-color: var(--ghost-accent-color);
        color: #fff;
    }
    .kg-button-card a.kg-btn {
        padding: 0 1.2em;
        height: 2.4em;
        line-height: 1em;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        font-size: .95em;
        font-weight: 600;
        text-decoration: none;
        border-radius: 5px;
        transition: opacity .2s ease-in-out;
        display: inline-flex;
        position: static;
        align-items: center;
        margin-bottom: 20px;
    }
}
.rdt.custom-calender input{
    background-color: transparent;
}