.has-bg {
  background-image: url("./assets/images/background-image.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}
.chat-bg {
  background-image: url("./assets/images/chat-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.5);
}
.courseBg {
  background-image: url("./assets/images/courses-pattern.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.5);
}
.after-dash:after {
  position: absolute;
  width: 100%;
  height: 1px;
  border: 1px dashed #e3e6eb;
  top: 50%;
  content: "";
  z-index: 1;
}
.has-center-line::after {
  position: absolute;
  height: 50%;
  width: 1px;
  left: 50%;
  content: "";
  z-index: -1;
  border: 1px dashed #e3e6eb;
  top: -20px;
}
.dotted-border-left {
  border-left: 1px dashed #bac3d2;
}
.left-dots:before {
  content: "";
  background-color: #bac3d1;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  left: -32px;
  top: 2px;
}
.dotted-border-left:nth-child(1) .left-dots:before {
  background-color: #3dbb69;
}
.dotted-border-left:nth-child(2) .left-dots:before {
  background-color:  #3dbb69;
  border: 6px solid rgb(245, 245, 245);
  width: 25px;
  height: 25px;
  left: -37px;
  top: -1px;
}

.dotted-border-left:nth-child(3) .left-dots:before {
  background-color: #ff6717;
}

.dotted-border-top {
  border-top: 1px dashed #bac3d2;
}
.top-dots:before {
  content: "";
  background-color: #bac3d1;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: -29px;
}
.dotted-border-top:nth-child(1) .top-dots:before {
  background-color: #ff6717;
  border: 6px solid rgb(245, 245, 245);
  width: 25px;
  height: 25px;
  left: 0;
  top: -33px;
}
.dotted-border-top:nth-child(2) .top-dots:before {
  background-color: #bac3d1;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c7c7c7;
}
.menu-item.active {
  background: white;
}
.progress {
  animation: progress 1s infinite linear;
}

.left-right {
  transform-origin: 0% 50%;
}
@keyframes progress {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

.phone-country-picker .PhoneInput {
  height: 41px;

  border: 1px solid #d1d5db;
  border-radius: 6px;
}
.phone-country-picker .PhoneInputCountry {
  padding: 10px;
  border-right: 1px solid #d1d5db;
}
.phone-country-picker .PhoneInput input {
  height: 100%;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 14px;
}
.phone-country-picker .PhoneInput input:focus {
  border: none;
  outline: none;
}

.rdt.custom-calender {
  width: 100%;
}
.rdt.custom-calender input {
  border: 1px solid #cfd8e7;
  padding: 12px 16px !important;
  width: 100%;
  border-radius: 8px;
  font-size: 14px;
  height: 46px;
}
.rdt.custom-calender input:focus {
  outline: none;
  border-color: #4087f3;
}
.rdt.custom-calender .rdtPicker {
  font-size: 12px;
  font-weight: normal;
}
.rdt.custom-calender .rdtPicker thead tr th {
  font-weight: 500;
}
.rdt.custom-calender .rdtPicker {
  box-shadow: 0 10px 30px rgb(83, 147, 244 0.1);
}
.rdt.custom-calender .rdtPicker td {
  border-radius: 4px;
}
.rdt.custom-calender .rdtPicker td.rdtActive,
.rdt.custom-calender .rdtPicker td.rdtActive:hover {
  background-color: #4087f3;
  border-radius: 4px;
}

.aspect-video iframe {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  border-radius: 12px;
}

/* Reposive Style */
.responsive-message {
  width: 100%;
  height: 100vh;
}
.responsive-message-inner {
  padding: 40px 20px;
  max-width: 400px;
  background-color: white;
  border-radius: 16px;
  /* box-shadow: 0 16px 30px #000; */
  width: 100%;
  position: relative;
  overflow: hidden;
}
.responsive-message-inner h4 {
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  font-weight: normal;
  color: #767676;
  margin-bottom: 16px;
}

.responsive-message-inner h4 .icon-wrapper svg {
  width: 50px;
  height: 50px;
}
.responsive-message-inner .l1x-title {
  font-size: 44px;
  font-weight: 700;
  color: #02012b;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.responsive-message-inner .l1x-title .app-badge {
  font-size: 26px;
  padding: 3px 12px 6px;
  border-radius: 30px;
  margin: 0 8px;
  border: 1px solid #02012b;
  line-height: 1;
}
.responsive-message-inner .input-wrapper {
  text-align: center;
}
.responsive-message-inner .input-wrapper .form-control {
  padding: 8px 14px;
  font-size: 14px;
  border: 1px solid #000;
  border-radius: 6px;
  margin-bottom: 14px;
  text-align: left;
}
.responsive-message-inner .input-wrapper .form-control:hover,
.responsive-message-inner .input-wrapper .form-control:active,
.responsive-message-inner .input-wrapper .form-control:focus {
  border-color: #00a018;
  box-shadow: 0 0 0 0.25rem #000;
}
.responsive-message-inner .input-wrapper .btn-register {
  font-size: 14px;
  padding: 6px 16px;
  border-radius: 30px;
  border: 1px solid #ff6619;
  color: #02012b;
  font-weight: 600;
}
.responsive-message-inner .input-wrapper .btn-register:hover {
  background: #ff6619;
  color: white;
}
.responsive-message-inner .email-send-patch {
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  color: #02012b;
  position: absolute;
  right: 0;
  top: 0;
  background: #000;
}
.card-hover-effect {
  border: 2px solid transparent;
  overflow: hidden;
}
.card-hover-effect:hover {
  border-color: #1875f3;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.25);
}
.InterfaceAnimation {
  animation: IntefaceAnimation 0.5s ease-in-out;
}

.gradient-bottom-black::after {
  content: "";
  width: 100%;
  height: 50%;
  background: rgb(5, 12, 46);
  background: linear-gradient(
    0deg,
    rgba(5, 12, 46, 1) 10%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

@keyframes IntefaceAnimation {
  0% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.block {
  word-wrap: break-word;
}
@media (max-width: 1024px) {
  .sidebar li a.active {
    background-color: #f0f8ff;
  }
}

@media (max-width: 480px) {
  .blog-grid {
    grid-template-columns: auto;
  }
}
