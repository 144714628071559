@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Kadwa:wght@400;700&family=Passion+One:wght@400;700;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css");

body {
  font-family: Inter;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: slnt 0;
}
